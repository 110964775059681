<template>
	<div class="w-100">
		<b-row>
			<b-col cols="6" class="d-flex align-items-center">
				<div class="mr-3 text-color-australien">
					<div class="fs-14">{{ labelForShowRecordsLoaded }}</div>
				</div>
			</b-col>
			<b-col cols="6">
				<div class="float-right">
					<div class="d-flex w-100">
						<div class="mr-3 w-100-px">
							<v-select
								v-model="perPage"
								:options="perPages"
								label="text"
								:reduce="(option) => option.value"
								:clearable="false"
								@input="handleChangePerPage"
							/>
						</div>
						<b-button
							variant="primary"
							size="sm"
							class="d-flex align-items-center mr-2 px-3"
							:disabled="disableButtonPrev"
							@click="handleClickPrevious"
						>
							<skip-back :size="16" />
							<div class="ml-2" style="margin-top: 1px">{{ FormMSG(23, 'Prev') }}</div>
						</b-button>
						<b-button class="d-flex align-items-center px-3" variant="primary" size="sm" :disabled="disableButtonNext" @click="handleClickNext">
							<div class="mr-2" style="margin-top: 1px">{{ FormMSG(24, 'Next') }}</div>
							<skip-forward :size="16" />
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { SkipBack, SkipForward, AlertTriangle } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
	name: 'CustomPagination',

	components: {
		SkipBack,
		SkipForward,
		AlertTriangle
	},

	mixins: [languageMessages],

	props: {
		totalRecords: { type: Number, default: 0 },
		initialPerpage: { type: Number, default: 100 },
		initialOffset: { type: Number, default: 0 },
		initialLimit: { type: Number, default: 100 },
		filterType: { type: Number, default: 0 }
	},

	data() {
		return {
			perPage: 100,
			oldPerPage: 100,
			offset: 0,
			limit: 100
		};
	},

	watch: {
		initialPerpage: {
			handler(val) {
				this.perPage = val;
			},
			immediate: true,
			deep: true
		},
		initialOffset: {
			handler(val) {
				this.offset = val;
			},
			immediate: true,
			deep: true
		},
		initialLimit: {
			handler(val) {
				this.limit = val;
			},
			immediate: true,
			deep: true
		},
		paginationFilter: {
			handler(newVal) {
				if (this.filterType === 1) {
					this.limit = newVal.forBudgetSalaries.recordLimit;
					this.offset = newVal.forBudgetSalaries.recordOffSet;
				} else if (this.filterType === 2) {
					this.limit = newVal.forBudgetExpenses.recordLimit;
					this.offset = newVal.forBudgetExpenses.recordOffSet;
				} else if (this.filterType === 3) {
					this.limit = newVal.forBudgetPO.recordLimit;
					this.offset = newVal.forBudgetPO.recordOffSet;
				} else if (this.filterType === 4) {
					this.limit = newVal.forBudgetExpenseSheet.recordLimit;
					this.offset = newVal.forBudgetExpenseSheet.recordOffSet;
				} else if (this.filterType === 5) {
					this.limit = newVal.forGreenTable.recordLimit;
					this.offset = newVal.forGreenTable.recordOffSet;
				}
			},
			immediate: true,
			deep: true
		},
		perPageFilter: {
			handler(newVal) {
				if (this.filterType === 1) {
					this.perPage = newVal.forBudgetSalaries;
				} else if (this.filterType === 2) {
					this.perPage = newVal.forBudgetExpenses;
				} else if (this.filterType === 3) {
					this.perPage = newVal.forBudgetPO;
				} else if (this.filterType === 4) {
					this.perPage = newVal.forBudgetExpenseSheet;
				} else if (this.filterType === 4) {
					this.perPage = newVal.forGreenTable;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			filterBudget: 'global-filter/filterBudget',
			paginationFilter: 'global-filter/paginationFilter',
			perPageFilter: 'global-filter/perPageFilter'
		}),

		perPages() {
			const list = [100, 200, 300, 500, 1000];
			let result = [];

			for (let i = 0; i < list.length; i++) {
				const element = list[i];
				result.push({
					text: element,
					value: element
				});
			}

			return result;
		},

		disableButtonPrev() {
			if (this.offset <= 0) {
				return true;
			}

			return false;
		},

		disableButtonNext() {
			if (this.limit >= this.totalRecords) {
				return true;
			}

			return false;
		},
		labelForShowRecordsLoaded() {
			let limit = this.totalRecords < this.limit ? this.totalRecords : this.limit;
			// return `${this.FormMSG(256, 'Showing')} ${this.offset} ${this.FormMSG(257, 'to')} ${limit} ${this.FormMSG(258, 'of')} ${
			// 	this.totalRecords
			// } ${this.FormMSG(154, 'records')}`
			return `${this.offset} - ${limit} ${this.FormMSG(258, 'of')} ${this.totalRecords} ${this.FormMSG(154, 'records')}`;
		}
	},

	methods: {
		...mapActions({
			updateFilter: 'global-filter/updateFilter',
			setPagination: 'global-filter/setPagination',
			setPerPageFilter: 'global-filter/setPerPageFilter'
		}),

		async handleChangePerPage(payload) {
			this.limit -= this.oldPerPage;
			this.perPage = payload;
			this.limit += payload;
			// if (this.offset > 0) {
			//     this.offset -= this.oldPerPage
			// }

			// if (this.limit > this.totalRecords) {
			//     this.limit = this.totalRecords
			// }

			this.oldPerPage = payload;

			this.actionForUpdateFilter({ limit: this.limit, offset: this.offset, perPage: payload });

			this.$emit('custom-pagination:change-per-page', {
				perPage: payload,
				limit: this.limit,
				offset: this.offset
			});
		},
		async actionForUpdateFilter({ limit, offset, perPage }) {
			if (this.filterType === 1) {
				if (!_.isNil(this.filterBudget.forBudgetSalaries) && this.filterBudget.forBudgetSalaries.id > 0) {
					await this.updateFilter({
						inputFilter: {
							recordLimit: limit,
							recordOffSet: offset
						},
						filterId: this.filterBudget.forBudgetSalaries.id
					});
				} else {
					this.setPagination({ limit, offset, filterType: this.filterType });
					this.setPerPageFilter({ perPage, filterType: this.filterType });
				}
			} else if (this.filterType === 2) {
				if (!_.isNil(this.filterBudget.forBudgetExpenses) && this.filterBudget.forBudgetExpenses.id > 0) {
					await this.updateFilter({
						inputFilter: {
							recordLimit: limit,
							recordOffSet: offset
						},
						filterId: this.filterBudget.forBudgetExpenses.id
					});
				} else {
					this.setPagination({ limit, offset, filterType: this.filterType });
					this.setPerPageFilter({ perPage, filterType: this.filterType });
				}
			} else if (this.filterType === 3) {
				if (!_.isNil(this.filterBudget.forBudgetPO) && this.filterBudget.forBudgetPO.id > 0) {
					await this.updateFilter({
						inputFilter: {
							recordLimit: limit,
							recordOffSet: offset
						},
						filterId: this.filterBudget.forBudgetPO.id
					});
				} else {
					this.setPagination({ limit, offset, filterType: this.filterType });
					this.setPerPageFilter({ perPage, filterType: this.filterType });
				}
			} else if (this.filterType === 4) {
				if (!_.isNil(this.filterBudget.forBudgetExpenseSheet) && this.filterBudget.forBudgetExpenseSheet.id > 0) {
					await this.updateFilter({
						inputFilter: {
							recordLimit: limit,
							recordOffSet: offset
						},
						filterId: this.filterBudget.forBudgetExpenseSheet.id
					});
				} else {
					this.setPagination({ limit, offset, filterType: this.filterType });
					this.setPerPageFilter({ perPage, filterType: this.filterType });
				}
			} else if (this.filterType === 5) {
				if (!_.isNil(this.filterBudget.forGreenTable) && this.filterBudget.forGreenTable.id > 0) {
					await this.updateFilter({
						inputFilter: {
							recordLimit: limit,
							recordOffSet: offset
						},
						filterId: this.filterBudget.forGreenTable.id
					});
				} else {
					this.setPagination({ limit, offset, filterType: this.filterType });
					this.setPerPageFilter({ perPage, filterType: this.filterType });
				}
			}
		},
		handleClickNext() {
			this.oldPerPage = this.perPage;
			this.limit = this.perPage;

			if (this.limit > this.totalRecords) {
				this.limit = this.totalRecords;
			}

			this.offset += this.perPage;

			if (this.offset > this.totalRecords) {
				this.offset = this.totalRecords;
			}

			this.actionForUpdateFilter({ limit: this.limit, offset: this.offset, perPage: this.perPage });

			this.$emit('custom-pagination:forward', {
				perPage: this.perPage,
				limit: this.limit,
				offset: this.offset
			});
		},
		handleClickPrevious() {
			this.oldPerPage = this.perPage;
			if (this.limit % this.perPage < this.perPage) {
				if (this.limit % this.perPage === 0) {
					this.limit = this.perPage;
				} else {
					this.limit = this.perPage;
				}
			} else {
				this.limit = this.perPage;
			}

			if (this.limit <= 0) {
				this.limit = this.perPage;
			}

			this.offset -= this.perPage;

			if (this.offset <= 0) {
				this.offset = 0;
			}

			this.actionForUpdateFilter({ limit: this.limit, offset: this.offset, perPage: this.perPage });

			this.$emit('custom-pagination:previous', {
				perPage: this.perPage,
				limit: this.limit,
				offset: this.offset
			});
		}
	}
};
</script>
